
    /deep/.redInput .el-input__inner{
        color: #FD4446;
    }
    /deep/.el-table__header-wrapper .el-checkbox .el-checkbox__input .el-checkbox__inner{
        display: none;
    }
    /deep/.el-checkbox .is-checked .el-checkbox__inner{
        background-color: #FD4446;
        border-color: #FD4446;
    }
    /deep/.is-indeterminate .el-checkbox__inner{
        background-color: #FD4446;
        border-color: #FD4446;
    }
    /deep/.el-button--default:hover{
        border-color: #FD4446;
        color: #FD4446;
        background: #ffffff;
    }
    .SetPromotionAdd{
        .SetPromotionAddHeader{
            p{
                line-height: 1;
                padding-top: 18px;
                color: #343434;
                font-size: 18px;
                font-weight: bold;
            }
        }
        .SetPromotionAddData{
            margin-top: 18px;
            background: #ffffff;
            padding: 20px;
            .bannerHeader{
                margin-top: 30px;
                display: flex;
                img{
                    margin-top: 20px;
                }
                display: flex;
                justify-content: center;
                .bannerHeaderOne{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    span{
                        display: inline-block;
                        width: 48px;
                        height: 48px;
                        background: #DCDCDC;
                        color: #ffffff;
                        font-size: 24px;
                        text-align: center;
                        line-height: 48px;
                        border-radius: 50%;
                    }
                    .isActive{
                        background: #FD4446;
                    }
                    b{
                        font-size: 16px;
                        font-weight: 400;
                        color: #333333;
                        line-height: 1;
                        margin-top: 20px;
                    }
                }
            }
        }
        .SetPromotionAddFill{
            background: #ffffff;
            padding: 20px 30px;
        }
    }
    .SetPromotionOne{
        .SetPromotionOneHeader{
            color: #343434;
            line-height: 1;
            .title{
                font-size: 18px;
            }
            .menuName{
                margin-top: 30px;
                span{
                    font-size: 16px;
                }
            }
            .menuShop{
                margin-top: 30px;
                span:nth-child(1){
                    font-size: 16px;
                }
                i{
                    font-size: 14px;
                    color: #FF9800;
                    margin: 0 10px;
                }
                .span{
                    font-size: 14px;
                }
            }
        }
        .SetPromotionOneTable{
            margin-top: 30px;
            .shopData{
                display: flex;
                align-items: center;
                /*justify-content: center;*/
                img{
                    width: 48px;
                    height: 48px;
                }
                span{
                    color: #333333;
                    font-size: 14px;
                    margin-left: 10px;
                    display: flex;
                    flex-direction: column;
                    b{
                        font-weight: 500;
                        display: inline-block;
                        width: 30px;
                        border: 1px solid #39CD7C;
                        color: #39CD7C;
                        font-size: 10px;
                        /*padding: 3px 6px;*/
                        height: 16px;
                        text-align: center;
                        line-height: 16px;
                    }
                }
            }
        }
        .SetPromotionOneText{
            display: flex;
            justify-content: space-between;
            line-height: 1;
            .text{
                margin-top: 20px;
                .text1{
                    span:nth-child(1){
                        color: #343434;
                        font-size: 18px;
                    }
                    span:nth-child(2){
                        font-size: 14px;
                        color: #FE4447;
                        margin-left: 12px;
                    }
                    span:nth-child(3){
                        font-size: 24px;
                        color: #FE4447;
                    }
                }
                .text2{
                    margin-top: 20px;
                    color: #9A9A9A;
                    font-size: 16px;
                }
            }
        }
        .SetPromotionOneBtn{
            margin-top: 44px;
            .btn{
                width: 100px;
                height: 40px;
                line-height: 0;
            }
            /*.btn:nth-child(2){*/
            /*    background: #FD4446;*/
            /*    border-color: #FD4446;*/
            /*    color: #ffffff;*/
            /*}*/
            span{
                color: #FE4447;
                font-size: 14px;
                margin-left: 20px;
            }
        }
    }
    /deep/ .el-input {
        &.is-error {
            .el-input__inner {
                border-color: #FD4446;
            }
        }
    }
    /deep/ .el-input-number {
        .el-input__inner {
            text-align: left;
        }
        &.isError {
            .el-input__inner {
                border-color: #FD4446;
            }
        }
    }
